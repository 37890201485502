import React, { useEffect, useState } from "react";
import { Col, Row, Button, FormGroup, Form, Label, Input } from "reactstrap";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
const PasswordReset = () => {
  let navigate = useNavigate();
  let [loading, setLoading] = useState(false);
  let [resetpass, setResetpass] = useState({
    password: "",
    passworderr: "",
    new_password: "",
    new_passworderr: "",
    confirm_new_password: "",
    confirm_new_passworderr: "",
  });
  const Resetpassword = (event) => {
    event.preventDefault();
    setLoading(true);
    let error = {
      status: 0,
      password: "",
      new_password: "",
      confirm_new_password: "",
    };
    if (resetpass.password == "") {
      error.password = "Please Enter Current Password.";
      error.status = 1;
    } else {
      error.password = "";
    }
    if (resetpass.new_password == "") {
      error.new_password = "Please Enter New Password.";
      error.status = 1;
    } else {
      error.new_password = "";
    }
    if (resetpass.confirm_new_password == "") {
      error.confirm_new_password = "Please Enter Confirm New Password.";
      error.status = 1;
    } else {
      error.confirm_new_password = "";
    }
    if (resetpass.new_password !== resetpass.confirm_new_password) {
      error.new_password = "New Password and Confirm New Password must match.";
      error.confirm_new_password =
        "New Password and Confirm New Password must match.";
      error.status = 1;
    } 
    setResetpass({
      ...resetpass,
      passworderr: error.password,
      new_passworderr: error.new_password,
      confirm_new_passworderr: error.confirm_new_password,
    });
    if (error.status == 1) {
      setLoading(false);
    }
    if (error.status == 0) {
      let config = {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "multipart/form-data",
          Authorization: localStorage.getItem("escrowToken"),
        },
      };

      let url = process.env.REACT_APP_BASEURL + "/v1/change_password/";
      const formData = new FormData();
      formData.append("password", resetpass.password);
      formData.append("new_password", resetpass.new_password);
      formData.append("confirm_new_password", resetpass.confirm_new_password);

      axios
        .post(url, formData, config)
        .then((res) => {
          setLoading(false);
          setResetpass({ ...resetpass });

          toast.success("Reset Password successfully", {
            autoClose: 3000,
            theme: "colored",
          });
           navigate("/dashboard");
        })
        .catch((err) => {
          setLoading(false);
          toast.error(err.response.data.message, {
            autoClose: 3000,
            theme: "colored",
          });
        });
    }
  };
  return (
    <>
      <Row style={{ marginTop: "20px", padding: "15px" }}>
        <Col lg={12}>
          <Form onSubmit={Resetpassword}>
            <FormGroup>
              <Label>Current Password</Label>
              <Input
                name="password"
                placeholder="Current Password"
                type="text"
                defaultValue={resetpass.password}
                onChange={(e) => {
                  e.target.value !== ""
                    ? setResetpass({
                        ...resetpass,
                        password: e.target.value,
                        passworderr: "",
                      })
                    : setResetpass({
                        ...resetpass,
                        password: "",
                        passworderr: "Current Password is required.",
                      });
                }}
              />
              <small className="text-danger">{resetpass.passworderr}</small>
            </FormGroup>
            <FormGroup>
              <Label>New Password</Label>
              <Input
                name="new_password"
                placeholder="New Password"
                type="text"
                defaultValue={resetpass.new_password}
                onChange={(e) => {
                  e.target.value !== ""
                    ? setResetpass({
                        ...resetpass,
                        new_password: e.target.value,
                        new_passworderr: "",
                      })
                    : setResetpass({
                        ...resetpass,
                        new_password: "",
                        new_passworderr: "New Password is required.",
                      });
                }}
              />
              <small className="text-danger">{resetpass.new_passworderr}</small>
            </FormGroup>
            <FormGroup>
              <Label>Confirm New Password</Label>
              <Input
                name="confirm_new_password"
                placeholder="Confirm New Password"
                type="text"
                defaultValue={resetpass.confirm_new_password}
                onChange={(e) => {
                  e.target.value !== ""
                    ? setResetpass({
                        ...resetpass,
                        confirm_new_password: e.target.value,
                        confirm_new_passworderr: "",
                      })
                    : setResetpass({
                        ...resetpass,
                        confirm_new_password: "",
                        confirm_new_passworderr:
                          "Confirm New Password is required.",
                      });
                }}
              />
              <small className="text-danger">
                {resetpass.confirm_new_passworderr}
              </small>
            </FormGroup>
            <div className="button-group">
              {loading ? (
                <Button
                  type="submit"
                  className="btn header-color"
                  style={{ borderColor: "#0149AD" }}
                  disabled
                >
                  Updating...
                  <span
                    className="spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                  />
                </Button>
              ) : (
                <Button
                  type="submit"
                  className="btn header-color"
                  style={{ borderColor: "#0149AD" }}
                >
                  Update
                </Button>
              )}
            </div>
          </Form>
        </Col>
      </Row>
    </>
  );
};

export default PasswordReset;
