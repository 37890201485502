import React, { useState, useEffect, useMemo } from "react";
import { Col, Row, Button, Table, Input } from "reactstrap";
import { FaFileDownload, FaSearch } from "react-icons/fa";
import { AiOutlineArrowDown } from "react-icons/ai";
import { BsArrowsAngleContract } from "react-icons/bs";
import Card from "react-bootstrap/Card";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { FundDepositListData } from "./mokeData/fundDepositListData";
import CustomPagination from "../Pagination/CustomPagination";
import Loader from "../../../layouts/loader/Loader";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
const FundDeposit = () => {
  let navigate = useNavigate();
  let PageSize = 10;
  // let [fundDeposit, setFundDeposit] = useState(FundDepositListData());
  let [fundDeposit, setFundDeposit] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getFundDepositList();
  }, []);
  const [currentPage, setCurrentPage] = useState(1);
  let [filterParams, setFilterParams] = useState({
    dateFrom: "",
    dateTo: "",
    s: "",
    accountId: "",
  });
  const onSearch = () => {
    if (filterParams) {
      getFundDepositList();
    }
  };

  const getFundDepositList = () => {
    // let url =
    //   process.env.REACT_APP_BASEURL +
    //   "/api/v1/transaction?page=" +
    //   currentPage +
    //   "&s=" +
    //   filterParams.s +
    //   "&dateFrom=" +
    //   filterParams.dateFrom +
    //   "&dateTo=" +
    //   filterParams.dateTo;
    setLoading(true);
    const config = {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        Authorization: localStorage.getItem("escrowToken"),
      },
    };
    let url = process.env.REACT_APP_BASEURL + `/v1/transactions/?in_out=credit`;
    axios
      .get(url, config)
      .then((res) => {
        setLoading(false);
        // console.log(res.data);
        setFundDeposit(res.data.data);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };
  // useMemo(() => {
  //   getFundDepositList();
  // }, [currentPage]);

  function exportToExcel(tableData) {
    if (tableData && tableData.length > 0) {
      const worksheet = XLSX.utils.json_to_sheet(tableData);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
      const excelBuffer = XLSX.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });
      const data = new Blob([excelBuffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
      });
      FileSaver.saveAs(data, "FundDepositList.xlsx");
    }
  }
  return (
    <>
      <Row>
        <Col
          className="header-color"
          style={{
            padding: "20px 20px 100px 20px",
          }}
        >
          <h3 className="text-white" style={{ fontWeight: "bold" }}>
            <BsArrowsAngleContract /> Funds Deposit Report{" "}
          </h3>
          <span className="text-white">Inward Funds Deposit</span>
        </Col>
      </Row>
      <Row style={{ marginTop: "-50px" }}>
        <Col lg={12}>
          <Card>
            <Card.Header
              className="subheader-color"
              style={{ backgroundColor: "", padding: "20px" }}
            >
              <div
                className="d-flex justify-content-between "
                style={{ color: "#0149AD" }}
              >
                <div style={{ marginLeft: "20px", fontWeight: "bold" }}>
                  {" "}
                  Deposit List
                </div>
                <span className="text-end">
                  <Button
                    variant="primary"
                    onClick={() => {
                      exportToExcel(fundDeposit);
                    }}
                    disabled={fundDeposit.length > 0 ? false : true}
                  >
                    <FaFileDownload className="text-light fs-6" />
                  </Button>
                </span>
              </div>
            </Card.Header>
            <Card.Body>
              {/* <Row className="mt-3 p-0">
                <Col lg={5} className="p-1 ps-2">
                  <Input
                    name="search"
                    type="text"
                    value={filterParams.s}
                    onChange={(e) => {
                      setFilterParams({ ...filterParams, s: e.target.value });
                    }}
                    placeholder="Search by Virtual ID, Transaction ID, Remitter name"
                  />
                </Col>
                <Col className="p-1">
                  <Input
                    name="dateFrom"
                    value={filterParams.dateFrom}
                    onChange={(e) => {
                      setFilterParams({
                        ...filterParams,
                        dateFrom: e.target.value,
                      });
                    }}
                    type="date"
                  />
                </Col>
                <Col className="p-1">
                  <Input
                    name="dateTo"
                    value={filterParams.dateTo}
                    onChange={(e) => {
                      setFilterParams({
                        ...filterParams,
                        dateTo: e.target.value,
                      });
                    }}
                    type="date"
                  />
                </Col>
                <Col>
                  <Button
                    onClick={() => onSearch()}
                    style={{
                      backgroundColor: "#a2751a",
                      border: "none",
                      marginRight: "10px",
                    }}
                  >
                    <FaSearch />{" "}
                  </Button>
                  <span
                    onClick={() => {
                      setFilterParams({
                        dateFrom: "",
                        dateTo: "",
                        s: "",
                        accountId: "",
                      });
                      getFundDepositList();
                    }}
                    style={{ color: "#0149AD", marginRight: "10px" }}
                  >
                    {" "}
                    clear
                  </span>
                  <Button
                    style={{
                      backgroundColor: "#0149AD",
                      borderRadius: "50%",
                      borderColor: "#0149AD",
                      marginLeft: "8px",
                    }}
                  >
                    <AiOutlineArrowDown />{" "}
                  </Button>
                </Col>
              </Row> */}

              <Table
                className="no-wrap mt-3 align-middle border-top"
                responsive
                borderless
              >
                <thead>
                  <tr>
                    <th>Transaction Date</th>
                    <th>Customer Name</th>
                    <th>Transaction ID</th>
                    <th>UpiId</th>
                    <th>Amount</th>
                    <th>Status</th>
                    <th>In/Out</th>
                  </tr>
                </thead>
                <tbody>
                  {loading ? (
                    <Loader />
                  ) : (
                    fundDeposit?.length > 0 &&
                    fundDeposit.map((item, index) => {
                      return (
                        <tr key={index} className="border-top">
                          <td>{item.txn_time}</td>
                          <td>{item.customer_name}</td>
                          <td>{item.ext_transaction_id}</td>
                          <td>{item.upi_id}</td>
                          <td>{item.amount}</td>
                          <td>{item.transaction_status}</td>
                          <td>{item.in_out}</td>
                        </tr>
                      );
                    })
                  )}
                  {!loading && fundDeposit?.length === 0 && (
                    <tr>
                      <td colSpan={7}>
                        <h6 className="align-middle text-center fs-3 my-5 py-5">
                          No Data Available
                        </h6>
                      </td>
                    </tr>
                  )}
                </tbody>
              </Table>
              <CustomPagination
                className="pagination-bar"
                currentPage={currentPage}
                totalCount={fundDeposit.length}
                pageSize={PageSize}
                onPageChange={(page) => setCurrentPage(page)}
              />
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default FundDeposit;
