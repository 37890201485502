import React from 'react'
import { Card, CardBody, CardSubtitle, CardText, Col, Row } from "reactstrap";
import image from "../../../assets/images/users/images.png"
const RewardBonus = () => {
    return (
        <div>
            <Row style={{ marginTop: '5px', padding: '15px', }} >
                <Card>
                    <CardBody>
                        <CardSubtitle
                            className="mb-2 text-mute"
                            tag="h6"
                        >
                           <span className='small fw-bold text-body-secondary'> 05 SEP 2023</span>
                        </CardSubtitle>
                        <CardText>
                            <Row>
                                <Col lg={12} className='d-flex align-items-center'>
                                    <span>
                                        <img
                                            alt="Card cap"
                                            src={image}
                                            style={{borderRadius:"50%"}}
                                            width="60" height="60"
                                            className='shadow-lg'
                                        />
                                    </span>
                                    <span className='ms-3 small fw-bold'><span className='fw-bold small'>MMTBLACK</span><br /><span className='small fw-normal text-secondary'>Earned With MMTBLACK</span><br />Expires on <span className='text-danger fw-normal'>4 Dec 23</span></span>
                                    <span className="fw-bold fs-4 text-success" style={{ marginLeft: "auto" }}>+ ₹0.00</span>
                                </Col>
                            </Row>
                        </CardText>
                        <hr className='m-0 p-0 text-info'/>
                    </CardBody>
                    <CardBody>
                        <CardSubtitle
                            className="mb-2 text-mute"
                            tag="h6"
                        >
                           <span className='small fw-bold text-body-secondary'> 07 SEP 2023</span>
                        </CardSubtitle>
                        <CardText>
                            <Row>
                                <Col lg={12} className='d-flex align-items-center'>
                                    <span>
                                        <img
                                            alt="Card cap"
                                            src={image}
                                            style={{borderRadius:"50%"}}
                                            width="60" height="60"
                                            className='shadow-lg'
                                        />
                                    </span>
                                    <span className='ms-3 small fw-bold'><span className='fw-bold small'>MMTBLACK</span><br /><span className='small fw-normal text-secondary'>Earned With MMTBLACK</span><br />Expires on <span className='text-danger fw-normal'>4 Dec 23</span></span>
                                    <span className="fw-bold fs-4 text-danger" style={{ marginLeft: "auto" }}>- ₹0.00</span>
                                </Col>
                            </Row>
                        </CardText>
                        <hr className='m-0 p-0 text-info'/>
                    </CardBody>                    
                </Card>
            </Row>
        </div>
    )
}
export default RewardBonus