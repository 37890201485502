import { Outlet, useNavigate } from "react-router-dom";
import React, { useState, useEffect } from "react";
import Sidebar from "./Sidebar";
import { MdOutlineLogout } from "react-icons/md";
import { HiChevronRight } from "react-icons/hi";
import "./sidebar.css";
import {
  Navbar,
  Collapse,
  NavbarBrand,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Dropdown,
  Button,
} from "reactstrap";
import Logo from "../assets/images/logos/blinkpaynewlogo.svg";
import user1 from "../assets/images/users/user.png";
import { GiHamburgerMenu } from "react-icons/gi";
import Footer from "./loader/Footer";
const FullLayout = () => {
  const [isOpen, setIsOpen] = React.useState(false);
  const [isClick, setIsCLick] = useState(false);
  const [dropdownOpen, setDropdownOpen] = React.useState(false);
  let navigate = useNavigate();
  const toggle = () => setDropdownOpen((prevState) => !prevState);
  const showMobilemenu = () => {
    document.getElementById("sidebarArea").classList.toggle("showSidebar");
  };

  useEffect(() => {
    // console.log("full Layoutcall");
  }, []);

  return (
    <main>
      {/********header**********/}
      <Navbar
        color="white"
        light
        expand="md"
        className="fix-header shadow"
        style={{ position: "fixed", top: 0, left: 0, right: 0, zIndex: 1 }}
      >
        <div className="d-flex align-items-center">
          <div className="ms-2 me-2 btn d-lg-block d-none">
            <GiHamburgerMenu
              size={25}
              style={{ color: "grey" }}
              onClick={() => setIsCLick(!isClick)}
            />
          </div>

          <div className="d-lg-block d-none me-5 pe-3">
            <img
              src={Logo}
              style={{ height: "55px", widht: "55px" }}
              alt="title"
            ></img>
          </div>
          <Button
            style={{ background: "white", border: "none" }}
            className=" d-lg-none"
            onClick={() => showMobilemenu()}
          >
            <GiHamburgerMenu size={25} style={{ color: "grey" }} />
          </Button>
          <NavbarBrand href="/">
            <img
              src={Logo}
              style={{ height: "40px", widht: "40px" }}
              className="d-lg-none"
              alt="title1"
            />
          </NavbarBrand>
        </div>
        <div className="hstack gap-2">
          <Collapse
            navbar
            isOpen={isOpen}
            className="d-flex justify-content-end"
          >
            {/* <span className="user-text"> Merchants</span> <HiChevronRight style={{ color: 'gray', fontSize: '20px' }} /> */}
            <Dropdown isOpen={dropdownOpen} toggle={toggle}>
              <DropdownToggle color="transparent">
                <img src={user1} alt="profile" className="img-user"></img>
              </DropdownToggle>
              <DropdownMenu style={{ borderRadius: "10px" }} className="shadow">
                {/* <DropdownItem className="d-flex p-0 pt-1">
                  <DropdownItem>
                    <img src={user1} alt="profile" width="80"></img>
                  </DropdownItem>
                  <DropdownItem className="ps-0 py-0">
                    <span
                      style={{
                        color: "black",
                        fontWeight: "500",
                        fontSize: "16px",
                      }}
                    >
                      Laetitia Shakti <br></br>
                    </span>
                    <span style={{ color: "gray" }}>
                      founder@laetitiaflnserv.com{" "}
                    </span>
                    <br></br>
                    <p style={{ color: "gray", marginTop: "15px" }}>
                      {" "}
                      Merchant Id: MR30926469365<br></br>
                      Role:Owner
                    </p>
                  </DropdownItem>
                </DropdownItem> */}
                {/* <DropdownItem>My Account</DropdownItem>
              <DropdownItem>Edit Profile</DropdownItem>
              <DropdownItem divider />
              <DropdownItem>My Balance</DropdownItem>
              <DropdownItem>Inbox</DropdownItem> */}
                {/* <hr></hr> */}
                <DropdownItem
                  onClick={() => {
                    localStorage.removeItem(
                      process.env.REACT_APP_LOCALSTORAGE_KEY
                    );
                    localStorage.clear();
                    navigate("/login");
                  }}
                  className="py-0 fs-5"
                >
                  <MdOutlineLogout style={{ color: "gray" }} /> Logout
                </DropdownItem>
              </DropdownMenu>
            </Dropdown>
            {/* <Dropdown isOpen={dropdownOpen} toggle={toggle}>
              <DropdownToggle color="transparent">
                <img
                  src={user1}
                  alt="profile"
                  className="img-user"
                
                ></img>
             
              </DropdownToggle>
              <DropdownMenu style={{ marginTop: '40px', borderRadius: '10px' }} className='shadow' >
                <DropdownItem className="d-flex p-0 pt-1">

                  <DropdownItem   >

                    <img
                      src={user1}
                      alt="profile"

                      width="80"
                    ></img>
                  </DropdownItem>
                  <DropdownItem className="ps-0 py-0">

                    <span style={{ color: "black", fontWeight: '500', fontSize: '16px' }}>Laetitia Shakti <br></br>
                    </span>
                    <span style={{ color: 'gray' }} >founder@laetitiaflnserv.com </span><br></br>
                    <p style={{ color: 'gray', marginTop: '15px' }}>   Merchant Id: MR30926469365<br></br>
                      Role:Owner</p>
                  </DropdownItem>



                </DropdownItem>
                <hr></hr>
                <DropdownItem onClick={() => { navigate('/login') }} className='py-0 fs-5'><MdOutlineLogout style={{ color: 'gray' }} /> Logout</DropdownItem>
              </DropdownMenu>
            </Dropdown> */}
          </Collapse>
        </div>
      </Navbar>
      <div className="pageWrapper d-lg-flex">
        {/********Sidebar**********/}
        {isClick ? null : (
          <aside className="sidebarArea shadow bg-white" id="sidebarArea">
            <Sidebar />
          </aside>
        )}
        {/********Content Area**********/}
        <div className="contentArea nav-res">
          {/********Middle Content**********/}

          <div style={{ minHeight: "90%", width: "96%", marginLeft: "2%" }}>
            <Outlet />
          </div>
          <Footer></Footer>
        </div>
      </div>
    </main>
  );
};

export default FullLayout;
