import { Col, Row, Button } from "reactstrap";
import React, { useEffect, useState } from "react";
import Card from "react-bootstrap/Card";
import Accordion from "react-bootstrap/Accordion";
import { TbActivityHeartbeat } from "react-icons/tb";
import { AiOutlineFileSearch } from "react-icons/ai";
import Chart from "react-apexcharts";
import "./style.css";
import axios from "axios";
import { errorHandler } from "../../utils/ErrorHandler";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import Loader from "../../layouts/loader/Loader";

function Dashboard() {
  let navigate = useNavigate();
  useEffect(() => {
    getWalletData();
    // getDashboardData()
  }, []);

  const [walletData, setWalletData] = useState();
  const [loading, setLoading] = useState(false);

  // API for Dashboard
  const getWalletData = () => {
    setLoading(true);
    let url = process.env.REACT_APP_BASEURL + "v1/wallet/";
    const config = {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        Authorization: localStorage.getItem("escrowToken"),
      },
    };
    axios
      .get(url, config)
      .then((res) => {
        if (res) {
          setWalletData(res.data.data);
          setLoading(false);
        }
      })
      .catch((error) => {
        setLoading(false);
        const getErrRes = errorHandler(error);
        if (getErrRes === 401) {
          toast.error("Something went wrong.", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 2000,
            theme: "colored",
          });
          navigate("/");
        } else {
          toast.error(getErrRes ? getErrRes : "Something went wrong.", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 2000,
            theme: "colored",
          });
        }
      });
  };

  const getDashboardData = () => {
    console.log("dashboard call");
    let url = process.env.REACT_APP_BASEURL + "/api/v1/merchant/balance";
    const getlocalStorage = JSON.parse(
      localStorage.getItem(process.env.REACT_APP_LOCALSTORAGE_KEY)
    );
    const config = {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        // "Accept" : "plain/text",
        Authorization: "Bearer " + getlocalStorage.token,
        "x-api-key": process.env.REACT_APP_X_API_KEY,
      },
    };
    axios
      .get(url, config)
      .then((res) => {
        if (res) {
          console.log("RESSSSSSS", JSON.stringify(res));
          // localStorage.removeItem(process.env.REACT_APP_LOCALSTORAGE_KEY)
        }
      })
      .catch((error) => {
        const getErrRes = errorHandler(error);
        if (getErrRes === 401) {
          toast.error("Something went wrong.", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 2000,
            theme: "colored",
          });
          navigate("/");
        } else {
          toast.error(getErrRes ? getErrRes : "Something went wrong.", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 2000,
            theme: "colored",
          });
        }
      });
  };

  return (
    <>
      {loading ? <Loader /> : null}
      <Row>
        <Col
          className="header-color"
          style={{ padding: "20px 20px 100px 20px" }}
        >
          <h2 className="text-white">
            <TbActivityHeartbeat></TbActivityHeartbeat> Dashboard
          </h2>
        </Col>
      </Row>
      <Row style={{ marginTop: "-50px" }}>
        <Col lg={6}>
          <Card>
            <Card.Header
              className="subheader-color"
              style={{ padding: "20px" }}
            >
              <span className="text-dark">Total Escrow Balance</span>
            </Card.Header>
            <Card.Body>
              ₹{" "}
              {walletData?.total_escrow_balance
                ? walletData?.total_escrow_balance
                : 0}
              <Card.Text></Card.Text>
            </Card.Body>
          </Card>
          <Card>
            <Card.Header
              className="subheader-color"
              style={{ padding: "20px" }}
            >
              <span className="text-dark">
                Total amount of Pending Transfers
              </span>
            </Card.Header>
            <Card.Body>
              ₹ {walletData?.pending_amount ? walletData?.pending_amount : 0}
              <Card.Text></Card.Text>
            </Card.Body>
          </Card>
          <Card>
            <Card.Header
              className="subheader-color"
              style={{ padding: "20px" }}
            >
              <span className="text-dark">Total no of Pending Transfers</span>
            </Card.Header>
            <Card.Body>
              {walletData?.no_of_pending_transfers
                ? walletData?.no_of_pending_transfers
                : 0}
              <Card.Text></Card.Text>
            </Card.Body>
          </Card>
        </Col>
        <Col lg={6}>
          <Card style={{ height: "420px" }}>
            <Card.Header
              className="subheader-color"
              style={{ padding: "20px" }}
            >
              <span className="text-dark">Virtual Account Balance</span>
            </Card.Header>
            <Card.Body>
              <Card.Text className="text-center mt-5">
                <AiOutlineFileSearch
                  style={{ fontSize: "95px", marginTop: "20px" }}
                />
                <br></br>
                <span style={{ fontWeight: "bold" }}>
                  You have not Created An Account Yet !!!
                </span>
                <br></br>
                <br></br>
                <Button
                  className="header-color"
                  style={{ border: "none", color: "white" }}
                >
                  Add Account{" "}
                </Button>
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      <Card
        className="bg-white p-3 mx-auto shadow"
        style={{ fontWeight: "bold", color: "#0149AD" }}
      >
        Select Dates- 15/07/2022 - 15/12/2022
      </Card>
      <Accordion defaultActiveKey={["0"]} alwaysOpen>
        <Accordion.Item eventKey="0">
          <Accordion.Header>Total Inward Fund Deposit</Accordion.Header>
          <Accordion.Body style={{ background: "white" }}>
            <Chart
              type="area"
              className="chart-width"
              height={300}
              series={[
                {
                  name: "Humidity",
                  data: [0, 0, 0, 0, 0],
                },
              ]}
              options={{
                title: {
                  style: { fontSize: 20 },
                },
                colors: ["#0149AD"],
                stroke: { width: 3, curve: "smooth" },
                fill: { opacity: 5 },

                xaxis: {
                  title: {
                    style: { fontSize: 20 },
                  },
                  categories: [
                    "july",
                    "Agust",
                    "September",
                    "October",
                    "November",
                  ],
                },

                yaxis: {
                  title: {
                    style: { fontSize: 20 },
                  },
                },
              }}
            ></Chart>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
      <Row className="mt-5">
        <Col lg={4}>
          <Card
            className="p-3"
            style={{ borderLeft: "3px solid #0149AD", color: "#0149AD" }}
          >
            Number of inward Deposits<br></br>
            <span className="fw-bold fs-4 mt-1">
              {walletData?.no_of_credits ? walletData?.no_of_credits : 0}
            </span>
          </Card>
        </Col>
        <Col lg={4}>
          <Card
            className="p-3"
            style={{ borderLeft: "3px solid #0149AD", color: "#0149AD" }}
          >
            Amount of Inward Transfers<br></br>
            <span className="fw-bold fs-4 mt-1">
              {" "}
              ₹
              {walletData?.amount_of_credits
                ? walletData?.amount_of_credits
                : 0}
            </span>
          </Card>
        </Col>
        <Col lg={4}>
          <Card
            className="p-3"
            style={{ borderLeft: "3px solid #0149AD", color: "#0149AD" }}
          >
            Number of Outward Transfers<br></br>
            <span className="fw-bold fs-4 mt-1">
              {" "}
              {walletData?.no_of_debits ? walletData?.no_of_debits : 0}
            </span>
          </Card>
        </Col>
        <Col lg={4}>
          <Card
            className="p-3"
            style={{ borderLeft: "3px solid #0149AD", color: "#0149AD" }}
          >
            Amount of Outward Transfers<br></br>
            <span className="fw-bold fs-4 mt-1">
              {" "}
              ₹{walletData?.amount_of_debit ? walletData?.amount_of_debit : 0}
            </span>
          </Card>
        </Col>
        <Col lg={4}>
          <Card
            className="p-3"
            style={{ borderLeft: "3px solid #0149AD", color: "#0149AD" }}
          >
            My Wallet<br></br>
            <span className="fw-bold fs-4 mt-1">
              ₹{walletData?.wallet_amount ? walletData?.wallet_amount : 0}
            </span>
          </Card>
        </Col>
      </Row>
    </>
  );
}
export default Dashboard;
