import React,{useEffect, useState} from 'react'
import Form from 'react-bootstrap/Form';
import { Col, Row, Input, Table, Button, ButtonDropdown } from "reactstrap";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { errorHandler } from "../../../utils/ErrorHandler";

const LinkPayee = ({query}) => {

    console.log("Aman " + query)
    let navigate = useNavigate();
    const [payeeList, setpayeeList]= useState([
        {
            payeeId: "PY0000000000",
            accountHolder: "John Doe",
            accountNumber: "9874521478512441",
            bankName: "State Bank of India",
            bankAddress: "Janakpuri, New Delhi",
            ifsc: "SBIN0030503",
            mobile: "8521479631",
            email: "john.doe@gmail.com",
            createdAt: "2020-12-14T05:37:51.465Z",
            updatedAt: "2020-12-15T05:37:51.465Z",
            createdBy: "jane.doe@gmail.com",
            documents: [
              {
                type: "cancelledCheque",
                file: "fileName.pdf",
                serverFile: "a707878d11e33c44c0a3d214670a6aa26bbcbbfa967ee5a6995aa155bba8b965.pdf"
              }
            ]
          },
          {
            payeeId: "PY0000000000",
            accountHolder: "John Doe",
            accountNumber: "9874521478512441",
            bankName: "State Bank of India",
            bankAddress: "Janakpuri, New Delhi",
            ifsc: "SBIN0030503",
            mobile: "8521479631",
            email: "john.doe@gmail.com",
            createdAt: "2020-12-14T05:37:51.465Z",
            updatedAt: "2020-12-15T05:37:51.465Z",
            createdBy: "jane.doe@gmail.com",
            documents: [
              {
                type: "cancelledCheque",
                file: "fileName.pdf",
                serverFile: "a707878d11e33c44c0a3d214670a6aa26bbcbbfa967ee5a6995aa155bba8b965.pdf"
              }
            ]
          },
          {
            payeeId: "PY0000000000",
            accountHolder: "John Doe",
            accountNumber: "9874521478512441",
            bankName: "State Bank of India",
            bankAddress: "Janakpuri, New Delhi",
            ifsc: "SBIN0030503",
            mobile: "8521479631",
            email: "john.doe@gmail.com",
            createdAt: "2020-12-14T05:37:51.465Z",
            updatedAt: "2020-12-15T05:37:51.465Z",
            createdBy: "jane.doe@gmail.com",
            documents: [
              {
                type: "cancelledCheque",
                file: "fileName.pdf",
                serverFile: "a707878d11e33c44c0a3d214670a6aa26bbcbbfa967ee5a6995aa155bba8b965.pdf"
              }
            ]
          }
    ])

    let [formData, setFormData] = useState({
        payeeId: '',
        share: '',
        shareUnit : '',
    });
    const [loading, setLoading] = useState(false);
    let [errorMessage, setErrorMessage] = useState('')

    useEffect(() => {
        // getPayeeList()
      }, []);
    
      const getPayeeList = () => {
        let url = process.env.REACT_APP_BASEURL + "/api/v1/payee";
        const getlocalStorage = JSON.parse(
          localStorage.getItem(process.env.REACT_APP_LOCALSTORAGE_KEY)
        );
        const config = {
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Content-Type": "application/json",
            // "Accept" : "plain/text",
            Authorization: "Bearer " + getlocalStorage.token,
            "x-api-key": process.env.REACT_APP_X_API_KEY,
          },
        };
        axios
          .get(url, config)
          .then((res) => {
            if (res) {
              console.log("RESSSSSSS", JSON.stringify(res));
              // localStorage.removeItem(process.env.REACT_APP_LOCALSTORAGE_KEY)
            }
          })
          .catch((error) => {
            // const getErrRes = errorHandler(error);
            // if (getErrRes === 401) {
            //   toast.error("Something went wrong.", {
            //     position: toast.POSITION.TOP_RIGHT,
            //     autoClose: 2000,
            //     theme: "colored",
            //   });
            //   navigate("/");
            // } else {
            //   toast.error(getErrRes ? getErrRes : "Something went wrong.", {
            //     position: toast.POSITION.TOP_RIGHT,
            //     autoClose: 2000,
            //     theme: "colored",
            //   });
            // }
          });
      };
      const handleSubmit = (e) => {
        e.preventDefault();

        if(!formData.payeeId){
            setErrorMessage('Payee is required.')
        }else if(!formData.share){
            setErrorMessage("Payout Share is required.")
        }else if(!formData.shareUnit){
            setErrorMessage("Unit is required.")
        }
        else {
            setErrorMessage('')
        }


        setLoading(true)

        if(!errorMessage){
            console.log("FormData", formData);  
            let url = process.env.REACT_APP_BASEURL + "api/v1/account"+ ''+ "link-payeeRe";
            const config = {
                headers: {
                    "Access-Control-Allow-Origin": "*",
                    "Content-Type": "application/json",
                    // "Accept" : "plain/text",
                    'Authorization' : 'Bearer ' + localStorage.getItem("token"),
                    "x-api-key": process.env.REACT_APP_X_API_KEY,
                },
            };

            let body = {
                shareUnit : formData.shareUnit,
                payee: {
                    payeeId : formData.payeeId,
                    share : formData.share
                }
            }
            
            axios.post(url, body, config).then((res) => {
                setLoading(false);
                console.log("Result" + res.data)
            }).catch((err) => {
                setLoading(false)
                console.log(err);
            })

        } else {
            setLoading(false)
        }
    }
      

    return (
        <div>
            <Row style={{ marginTop: '20px', padding: '15px', }} >
            <Form onSubmit={handleSubmit}>
                {errorMessage && 
                    <Col className="mt-1" >
                        <div className="alert alert-danger" role="alert">
                            {errorMessage}
                            <Button onClick={()=>setErrorMessage('')} type="submit" className="close left" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                                </Button>
                        </div>
                    </Col>
                }
                <Row className="mt-3">
                    <Col lg='4'>
                        <label>Payee</label>
                        <Form.Select className="mt-2" onChange={(e) => {setFormData({ ...formData, payeeId: e.target.value})}}>
                        <option value={''} >Select Payee</option>

                        { payeeList?.length > 0 &&
                        payeeList.map((item, index) => {
                      return (
                       <option value={item.payeeId}> {item.accountHolder}</option> 
                      );
                    })}
                        </Form.Select>
                    </Col>
                    <Col lg='3'>
                        <label>Payout Share</label>
                        <Input value={formData.share} name="batch_name" type="number" placeholder="Enter payout share" className='mt-2'
                        onChange={(e) => {setFormData({ ...formData, share: e.target.value})}} />
                    </Col>
                    <Col lg='3' >
                        <label>Unit</label>
                        <Form.Select className="mt-2" onChange={(e) => {setFormData({ ...formData, shareUnit: e.target.value})}} >
                            <option value={''} >Select Unit</option>
                            <option value={'%'} >%</option>
                            <option value={'INR'} >INR</option>
                        </Form.Select>
                    </Col>
                    <Col className='' >
                    {loading ?
                        // <Button type="submit" className="btn" style={{ background: '#a2751a', borderColor: '#a2751a' }} disabled>Submit...
                        //     <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true" />
                        // </Button> 
                            <Button className="header-color" style={{borderColor:'#0149AD',marginTop:'30px'}} disabled>ADD...
                            <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true" />
                            </Button>
                        :

                        <Button className="header-color" style={{ borderColor:'#0149AD',marginTop:'30px'}}>ADD</Button>
                    }
                    </Col>
                    <div style={{ color: "#4D4D4D", fontSize: '15px',marginTop:'20px' }}>Schedule Auto Transfer</div>
                    <div>
                        <Form.Check type="switch" id="custom-switch" />
                    </div>
                </Row>
            </Form>
                <Table className="no-wrap mt-5 align-middle border-top" responsive borderless>
                    <thead>
                        <tr>
                            <th>S.NO</th>
                            <th>Payee Name</th>
                            <th>Payee Email</th>
                            <th>Share(0%) </th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr className="border-top">
                            <td>1</td>
                            <td>Renu</td>
                            <td>renu@gmail</td>
                            <td>50%</td>
                            <td>True</td>
                        </tr>
                    </tbody>
                </Table>
                <Col>
                    <Button className="btn mt-1 header-color" style={{ borderColor: '#0149AD' }}>
                        Next
                    </Button>
                </Col>


            </Row>
        </div>
    )
}

export default LinkPayee
