import React from 'react'
import { Row, Table } from "reactstrap";
const CashTransaction = () => {
    return (
        <div>
            <Row style={{ marginTop: '5px', padding: '15px', }} >
                <Table className="no-wrap mt-3 align-middle border-top" responsive borderless>
                    <thead className='fs-6'>
                        <tr>
                            <th>Name</th>
                            <th>Amount</th>
                            <th>Earned With</th>
                            <th>Start Date</th>
                            <th>Expires On</th>
                            <th>Type</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr className="border-top">
                            <td>Hello3</td>
                            <td>89898</td>
                            <td>Hello</td>
                            <td>07/08/2020</td>
                            <td>07/08/2020</td>
                            <td>Credited</td>
                        </tr>
                    </tbody>
                </Table>
            </Row>
        </div>
    )
}
export default CashTransaction