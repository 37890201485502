import React, {  useEffect } from 'react'
import {useNavigate } from 'react-router-dom';
import FullLayout from '../layouts/FullLayout';
import FullLayoutLogin from '../layouts/FullLayoutLogin';

const PrivateRoutes = () => {
    let navigate = useNavigate();
        useEffect(() => {
            // console.log("PrivateRoutes call");
            if(!localStorage.getItem(process.env.REACT_APP_LOCALSTORAGE_KEY)) {
                navigate("/login")
            }else{
                navigate("/dashboard")
            }
        }, []);
     let auth =  <FullLayoutLogin />  

    if (localStorage.getItem(process.env.REACT_APP_LOCALSTORAGE_KEY)) {
        auth = <FullLayout />
    }
    else
        auth = <FullLayoutLogin /> 
    return (
        auth 
    )
}

export default PrivateRoutes