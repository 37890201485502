import { Button, Nav, NavItem } from "reactstrap";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { VscChevronDown, VscChevronRight } from "react-icons/vsc";
import { TbActivityHeartbeat } from "react-icons/tb";
import { CiMobile4, CiWallet } from "react-icons/ci";
import { AiFillDashboard } from "react-icons/ai";
import { BsBook, BsArrowRightShort } from "react-icons/bs";
import { FiSend, FiUser, FiUsers, FiDownload } from "react-icons/fi";
import { TbTable } from "react-icons/tb";
import { FaLessThanEqual, FaWallet } from "react-icons/fa";
import { useState } from "react";
import "./sidebar.css";
import { FaUser } from "react-icons/fa";

const Sidebar = () => {
  const showMobilemenu = () => {
    document.getElementById("sidebarArea").classList.toggle("showSidebar");
  };
  let location = useLocation();
  const [openMenu, setOpenMenu] = useState(false);
  const [openMenu2, setOpenMenu2] = useState(false);
  const [openMenu3, setOpenMenu3] = useState(false);
  const [openMenu4, setOpenMenu4] = useState(false);
  const [openMenu5, setOpenMenu5] = useState(false);
  const [openMenu6, setOpenMenu6] = useState(false);
  const [openMenu7, setOpenMenu7] = useState(false);
  const [openMenu8, setOpenMenu8] = useState(false);

  let navigate = useNavigate();
  return (
    <div className="bg-white  ">
      <div className="d-flex">
        <Button
          color="black"
          className="ms-auto text-white d-lg-none"
          onClick={() => showMobilemenu()}
        >
          <i className="bi bi-x text-black"></i>
        </Button>
      </div>
      <div className="p-3 mt-2 res-sidebar ">
        <Nav vertical className="sidebarNav">
          <NavItem className="sidenav-bg fw-bold">
            <Link
              to="/dashboard"
              className={
                location.pathname === "/dashboard"
                  ? "active nav-link py-3 "
                  : "nav-link py-3"
              }
            >
              <span
                className="ms-3 d-inline-block "
                onClick={() => {
                  setOpenMenu(false);
                  setOpenMenu2(false);
                  setOpenMenu3(false);
                  setOpenMenu4(false);
                  setOpenMenu5(false);
                  setOpenMenu6(false);
                  setOpenMenu7(false);
                }}
              >
                <TbActivityHeartbeat /> Dashboard{" "}
              </span>
            </Link>
          </NavItem>
          {/* <NavItem className="sidenav-bg fw-bold">
            <Link
              to='/interactiveDashboard'
              className={
                location.pathname === "/interactiveDashboard"
                  ? "active nav-link py-3 "
                  : "nav-link py-3"
              }

            >

              <span className="ms-3 d-inline-block " onClick={() => { setOpenMenu(false); setOpenMenu2(false); setOpenMenu3(false); setOpenMenu4(false); setOpenMenu5(false); setOpenMenu6(false); setOpenMenu7(false) }} ><AiFillDashboard /> Interactive Dashboard </span>
            </Link>
          </NavItem> */}
          {/* <NavItem className="sidenav-bg ">
            <div className="nav-link ">
              <span className="ms-3 d-inline-block fw-bold " onClick={() => { setOpenMenu(!openMenu); setOpenMenu2(false); setOpenMenu3(false); setOpenMenu4(false); setOpenMenu5(false); setOpenMenu6(false); setOpenMenu7(false) }}  ><CiMobile4 /> Account {openMenu ? <VscChevronDown></VscChevronDown> : <VscChevronRight />}</span>
            </div>
            {openMenu ? <><div ><NavItem className="sidenav-bg  " style={{ marginTop: '-20px' }} onClick={() => { navigate('/accountlist') }}>
              <span className={
                location.pathname === "/accountlist"
                  ? "active nav-link py-3 ms-4 fw-bold "
                  : "nav-link py-3 ms-4  "
              } style={{ fontSize: '14px' }}><BsArrowRightShort /> List</span>
            </NavItem>
              <NavItem className="sidenav-bg  " style={{ marginTop: '-30px' }} onClick={() => { navigate('/accounttabs') }}>
                <span className={
                  location.pathname === "/accounttabs"
                    ? "active nav-link py-3 ms-4 fw-bold "
                    : "nav-link py-3 ms-4"
                } style={{ fontSize: '14px' }}><BsArrowRightShort />Add Account</span>
              </NavItem></div> </> : null}
          </NavItem> */}
          <NavItem className="sidenav-bg ">
            <div className="nav-link py-3">
              <span
                className="ms-3 d-inline-block fw-bold"
                onClick={() => {
                  setOpenMenu(false);
                  setOpenMenu2(false);
                  setOpenMenu3(false);
                  setOpenMenu4(false);
                  setOpenMenu5(false);
                  setOpenMenu6(false);
                  setOpenMenu7(!openMenu7);
                }}
              >
                <FaUser /> Users{" "}
                {openMenu7 ? (
                  <VscChevronDown></VscChevronDown>
                ) : (
                  <VscChevronRight />
                )}
              </span>
            </div>
            {openMenu7 ? (
              <>
                <div>
                  <NavItem
                    className="sidenav-bg  "
                    style={{ marginTop: "-20px" }}
                    onClick={() => {
                      navigate("/admin");
                    }}
                  >
                    <span
                      className={
                        location.pathname === "/admin"
                          ? "active nav-link py-3 ms-4 fw-bold "
                          : "nav-link py-3 ms-4 "
                      }
                      style={{ fontSize: "14px" }}
                    >
                      <BsArrowRightShort />
                      {localStorage.getItem("user_role") === "sub-admin"
                        ? "Add Bank Details"
                        : "Sub Admin"}
                    </span>
                  </NavItem>
                  {/* <NavItem className="sidenav-bg  " style={{ marginTop: '-30px' }} onClick={() => { navigate('/superdist') }}>
                <span className={
                  location.pathname === "/superdist"
                    ? "active nav-link py-3 ms-4 fw-bold "
                    : "nav-link py-3 ms-4 "
                } style={{ fontSize: '14px' }}><BsArrowRightShort />Super Dist</span>
              </NavItem> */}
                  <NavItem
                    className="sidenav-bg  "
                    style={{ marginTop: "-30px" }}
                    onClick={() => {
                      navigate("/dist");
                    }}
                  >
                    <span
                      className={
                        location.pathname === "/dist"
                          ? "active nav-link py-3 ms-4 fw-bold "
                          : "nav-link py-3 ms-4"
                      }
                      style={{ fontSize: "14px" }}
                    >
                      <BsArrowRightShort />
                      Dist
                    </span>
                  </NavItem>
                  <NavItem
                    className="sidenav-bg  "
                    style={{ marginTop: "-30px" }}
                    onClick={() => {
                      navigate("/retailer");
                    }}
                  >
                    <span
                      className={
                        location.pathname === "/retailer"
                          ? "active nav-link py-3 ms-4 fw-bold "
                          : "nav-link py-3 ms-4 "
                      }
                      style={{ fontSize: "14px" }}
                    >
                      <BsArrowRightShort />
                      Retailer
                    </span>
                  </NavItem>
                </div>{" "}
              </>
            ) : null}
          </NavItem>
          {/* <NavItem className="sidenav-bg ">
            <div className="nav-link py-3">
              <span className="ms-3 d-inline-block fw-bold " onClick={() => { setOpenMenu(false); setOpenMenu2(!openMenu2); setOpenMenu3(false); setOpenMenu4(false); setOpenMenu5(false); setOpenMenu6(false); setOpenMenu7(false) }} ><FiSend /> Create Transfer {openMenu2 ? <VscChevronDown></VscChevronDown> : <VscChevronRight />}</span>
            </div>
            {openMenu2 ? <><NavItem className="sidenav-bg  " style={{ marginTop: '-20px' }} onClick={() => { navigate('/singletransfer') }}>
              <span className={
                location.pathname === "/singletransfer"
                  ? "active nav-link py-3 ms-4 fw-bold "
                  : "nav-link py-3 ms-4 "
              } style={{ fontSize: '14px' }}><BsArrowRightShort />Single Transfer</span>
            </NavItem><br></br>
              <NavItem className="sidenav-bg  " style={{ marginTop: '-50px' }} onClick={() => { navigate('/bulktransfer') }}>
                <span className={
                  location.pathname === "/bulktransfer"
                    ? "active nav-link py-3 ms-4 fw-bold "
                    : "nav-link py-3 ms-4 "
                } style={{ fontSize: '14px' }}><BsArrowRightShort />Bulk Transfer</span>
              </NavItem></> : null}
          </NavItem> */}
          {/* <NavItem className="sidenav-bg ">
            <div className="nav-link py-3">
              <span className="ms-3 d-inline-block  fw-bold" onClick={() => { setOpenMenu(false); setOpenMenu2(false); setOpenMenu3(!openMenu3); setOpenMenu4(false); setOpenMenu5(false); setOpenMenu6(false); setOpenMenu7(false) }} ><FiUsers /> Manage Payees{openMenu3 ? <VscChevronDown></VscChevronDown> : <VscChevronRight />}</span>
            </div>

            {openMenu3 ? <><NavItem className="sidenav-bg  " style={{ marginTop: '-20px' }} onClick={() => { navigate('/payeeslist') }}>
              <span className={
                location.pathname === "/payeeslist"
                  ? "active nav-link py-3 ms-4 fw-bold "
                  : "nav-link py-3 ms-4 "
              } style={{ fontSize: '14px' }}><BsArrowRightShort />List</span>
            </NavItem><br></br>
              <NavItem className="sidenav-bg  " style={{ marginTop: '-50px' }} onClick={() => { navigate('/payees') }}>
                <span className={
                  location.pathname === "/payees"
                    ? "active nav-link py-3 ms-4 fw-bold "
                    : "nav-link py-3 ms-4 "
                } style={{ fontSize: '14px' }}><BsArrowRightShort />Add Account</span>
              </NavItem></> : null}
          </NavItem> */}
          <NavItem className="sidenav-bg ">
            <div className="nav-link py-3">
              <span
                className="ms-3 d-inline-block fw-bold "
                onClick={() => {
                  setOpenMenu(false);
                  setOpenMenu2(false);
                  setOpenMenu3(false);
                  setOpenMenu4(!openMenu4);
                  setOpenMenu5(false);
                  setOpenMenu6(false);
                  setOpenMenu7(false);
                }}
              >
                <BsBook /> My Passbook{" "}
                {openMenu4 ? (
                  <VscChevronDown></VscChevronDown>
                ) : (
                  <VscChevronRight />
                )}
              </span>
            </div>
            {openMenu4 ? (
              <>
                <NavItem
                  className="sidenav-bg  p-0 "
                  style={{ marginTop: "-20px" }}
                  onClick={() => {
                    navigate("/funddeposit");
                  }}
                >
                  <span
                    className={
                      location.pathname === "/funddeposit"
                        ? "active nav-link py-2 ms-3 fw-bold"
                        : "nav-link py-2 ms-3"
                    }
                    style={{ fontSize: "15px" }}
                  >
                    <BsArrowRightShort />
                    Inward Funds Deposit
                  </span>
                </NavItem>
                <br></br>
                <NavItem
                  className="sidenav-bg  p-0"
                  style={{ marginTop: "-30px" }}
                  onClick={() => {
                    navigate("/fundtransfer");
                  }}
                >
                  <span
                    className={
                      location.pathname === "/fundtransfer"
                        ? "active nav-link py-1 ms-3 fw-bold"
                        : "nav-link py-1 ms-3 "
                    }
                    style={{ fontSize: "15px" }}
                  >
                    <BsArrowRightShort />
                    Outward Fund Transfer
                  </span>
                </NavItem>
              </>
            ) : null}
          </NavItem>
          <NavItem className="sidenav-bg ">
            <Link
              to="/myWalletTab"
              className={
                location.pathname === "/myWalletTab"
                  ? "active nav-link py-3"
                  : "nav-link py-3"
              }
            >
              <span
                className="ms-3 d-inline-block fw-bold "
                onClick={() => {
                  setOpenMenu(false);
                  setOpenMenu2(false);
                  setOpenMenu3(false);
                  setOpenMenu4(false);
                  setOpenMenu5(false);
                  setOpenMenu6(false);
                  setOpenMenu7(false);
                }}
              >
                <CiWallet /> My Wallet{" "}
              </span>
            </Link>
          </NavItem>
          {/* <NavItem className="sidenav-bg ">
            <div className="nav-link py-3">

              <span className="ms-3 d-inline-block fw-bold" onClick={() => { setOpenMenu(false); setOpenMenu2(false); setOpenMenu3(false); setOpenMenu4(false); setOpenMenu5(!openMenu5); setOpenMenu6(false); setOpenMenu7(false) }} ><FiDownload /> Challans  {openMenu5 ? <VscChevronDown></VscChevronDown> : <VscChevronRight />}</span>
            </div>

            {openMenu5 ? <><NavItem className="sidenav-bg  " style={{ marginTop: '-20px' }} onClick={() => { navigate('/challanlist') }}>

              <span className={
                location.pathname === "/challanlist"
                  ? "active nav-link py-3 ms-4 fw-bold "
                  : "nav-link py-3 ms-4 "
              } style={{ fontSize: '14px' }}><BsArrowRightShort />List</span>
            </NavItem>
              <br></br>
              <NavItem className="sidenav-bg  " style={{ marginTop: '-50px' }} onClick={() => { navigate('/challancreate') }}>

                <span className={
                  location.pathname === "/challancreate"
                    ? "active nav-link py-3 ms-4 fw-bold "
                    : "nav-link py-3 ms-4 "
                } style={{ fontSize: '14px' }}><BsArrowRightShort />Create Challan</span>
              </NavItem></> : null}
          </NavItem> */}
          {/* <NavItem className="sidenav-bg ">
            <div className="nav-link py-3">
              <span className="ms-3 d-inline-block fw-bold" onClick={() => { setOpenMenu(false); setOpenMenu2(false); setOpenMenu3(false); setOpenMenu4(false); setOpenMenu5(false); setOpenMenu6(!openMenu6); setOpenMenu7(false) }} ><TbTable /> Escrow Pages  {openMenu6 ? <VscChevronDown></VscChevronDown> : <VscChevronRight />}</span>
            </div>
            {openMenu6 ? <><NavItem className="sidenav-bg  " style={{ marginTop: '-20px' }} onClick={() => { navigate('/escrowlist') }}>
              <span className={
                location.pathname === "/escrowlist"
                  ? "active nav-link py-3 ms-4 fw-bold "
                  : "nav-link py-3 ms-4 "
              } style={{ fontSize: '14px' }}><BsArrowRightShort />List</span>
            </NavItem><br></br>
              <NavItem className="sidenav-bg  p-0 " style={{ marginTop: '-50px' }} onClick={() => { navigate('/createescrow') }}>
                <span className={
                  location.pathname === "/createescrow"
                    ? "active nav-link py-3 ms-4 fw-bold"
                    : "nav-link py-3 ms-4 "
                } style={{ fontSize: '14px' }}><BsArrowRightShort />Create Escrow </span>
              </NavItem></> : null}
          </NavItem> */}

          {/* <NavItem className="sidenav-bg ">
            <Link
              to='/manageteams'
              className={
                location.pathname === "/manageteams"
                  ? "active nav-link py-3"
                  : "nav-link py-3"
              }
            >

              <span className="ms-3 d-inline-block fw-bold " onClick={() => { setOpenMenu(false); setOpenMenu2(false); setOpenMenu3(false); setOpenMenu4(false); setOpenMenu5(false); setOpenMenu6(false); setOpenMenu7(false) }}  ><FiUsers /> Manage Teams </span>
            </Link>
          </NavItem> */}
          {/* <NavItem className="sidenav-bg ">
            <Link
              to='/payment'
              className={
                location.pathname === "/payment"
                  ? "active nav-link py-3"
                  : "nav-link py-3"
              }
            >

              <span className="ms-3 d-inline-block fw-bold " onClick={() => { setOpenMenu(false); setOpenMenu2(false); setOpenMenu3(false); setOpenMenu4(false); setOpenMenu5(false); setOpenMenu6(false); setOpenMenu7(false) }}  ><FaWallet /> Payment </span>
            </Link>
          </NavItem> */}
          <NavItem className="sidenav-bg ">
            <div className="nav-link py-3">
              <span
                className="ms-3 d-inline-block fw-bold"
                onClick={() => {
                  setOpenMenu(false);
                  setOpenMenu2(false);
                  setOpenMenu3(false);
                  setOpenMenu4(false);
                  setOpenMenu5(false);
                  setOpenMenu6(false);
                  setOpenMenu7(false);
                  setOpenMenu8(!openMenu8);
                }}
              >
                <TbTable /> Payment{" "}
                {openMenu8 ? (
                  <VscChevronDown></VscChevronDown>
                ) : (
                  <VscChevronRight />
                )}
              </span>
            </div>
            {openMenu8 ? (
              <>
                <NavItem
                  className="sidenav-bg  "
                  style={{ marginTop: "-20px" }}
                  onClick={() => {
                    navigate("/payment");
                  }}
                >
                  <span
                    className={
                      location.pathname === "/payment"
                        ? "active nav-link py-3 ms-4 fw-bold "
                        : "nav-link py-3 ms-4 "
                    }
                    style={{ fontSize: "14px" }}
                  >
                    <BsArrowRightShort />
                    VPA
                  </span>
                </NavItem>
                <br></br>
                <NavItem
                  className="sidenav-bg  p-0 "
                  style={{ marginTop: "-50px" }}
                  onClick={() => {
                    navigate("/transactionhistory");
                  }}
                >
                  <span
                    className={
                      location.pathname === "/transactionhistory"
                        ? "active nav-link py-3 ms-4 fw-bold"
                        : "nav-link py-3 ms-4 "
                    }
                    style={{ fontSize: "14px" }}
                  >
                    <BsArrowRightShort />
                    TransactionHistory{" "}
                  </span>
                </NavItem>
              </>
            ) : null}
          </NavItem>

          <NavItem className="sidenav-bg ">
            <Link
              to='/profile'
              className={
                location.pathname === "/profile"
                  ? "active nav-link py-3"
                  : "nav-link py-3"
              }
            >

              <span className="ms-3 d-inline-block fw-bold " onClick={() => { setOpenMenu(false); setOpenMenu2(false); setOpenMenu3(false); setOpenMenu4(false); setOpenMenu5(false); setOpenMenu6(false); setOpenMenu7(false) }}   ><FiUser /> My Profile </span>
            </Link>
          </NavItem>
          {/* 
          <NavItem className="sidenav-bg ">
            <Link
              to='/bussinessprofile'
              className={
                location.pathname === "/bussinessprofile"
                  ? "active nav-link py-3"
                  : "nav-link py-3"
              }
            >
              
              <span className="ms-3 d-inline-block fw-bold" onClick={() => { setOpenMenu(false); setOpenMenu2(false); setOpenMenu3(false); setOpenMenu4(false); setOpenMenu5(false); setOpenMenu6(false); setOpenMenu7(false) }}   ><FiUser/> Bussiness Profile </span>
            </Link>
          </NavItem> */}

          {/* <NavItem className="sidenav-bg setting" >
            <Link
              to='/setting'
              className={
                location.pathname === "/setting"
                  ? "active nav-link py-3"
                  : "nav-link py-3"
              }
            >
  <span className="ms-3 d-inline-block "  ><FiSettings /> Setiing </span>
            </Link>
          </NavItem> */}
        </Nav>
      </div>
    </div>
  );
};

export default Sidebar;
