import React, { useEffect, useState } from "react";
import { Col, Row, Input, Table, Button } from "reactstrap";
import { FaFileDownload, FaSearch } from "react-icons/fa";
import { AiOutlineArrowDown } from "react-icons/ai";
import axios from "axios";
import Loader from "../../../layouts/loader/Loader";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";

const SingleTransferTab = () => {
  let [singleTransferList, setSingleTransferList] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getSingleTransferList();
  }, []);

  const getSingleTransferList = () => {
    setLoading(true);
    const config = {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        Authorization: localStorage.getItem("escrowToken"),
      },
    };
    let url = process.env.REACT_APP_BASEURL + "/v1/transactions/?in_out=debit";
    axios
      .get(url, config)
      .then((res) => {
        setLoading(false);
        // console.log(res.data);
        setSingleTransferList(res.data.data);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };

  function exportToExcel(tableData) {
    if (tableData && tableData.length > 0) {
      const worksheet = XLSX.utils.json_to_sheet(tableData);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
      const excelBuffer = XLSX.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });
      const data = new Blob([excelBuffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
      });
      FileSaver.saveAs(data, "FundDepositList.xlsx");
    }
  }
  return (
    <div>
      <Row style={{ marginTop: "5px", padding: "15px" }}>
        {/* <Row className="mt-3 ">
          <Col lg={5} className="p-1 ps-2">
            <Input
              name="search"
              type="text"
              placeholder="Search by Batc ID, Payment ID, Beneficiary name"
            />
          </Col>
          <Col className="p-1">
            <Input name="date" type="date" />
          </Col>
          <Col className="p-1">
            <Input name="date2" type="date" />
          </Col>

          <Col>
            <Button
              style={{
                backgroundColor: "#a2751a",
                border: "none",
                marginRight: "10px",
              }}
            >
              <FaSearch />{" "}
            </Button>
            <span style={{ color: "#0149AD", marginRight: "10px" }}>
              {" "}
              clear
            </span>
            <Button
              style={{
                backgroundColor: "#0149AD",
                borderRadius: "50%",
                borderColor: "#0149AD",
                marginLeft: "8px",
              }}
            >
              <AiOutlineArrowDown />{" "}
            </Button>
          </Col>
        </Row> */}
        <div className="pe-3 text-end">
          <Button
            variant="primary"
            onClick={() => {
              exportToExcel(singleTransferList);
            }}
            disabled={singleTransferList.length > 0 ? false : true}
          >
            <FaFileDownload className="text-light fs-6" /> Export File
          </Button>
        </div>
        <Table
          className="no-wrap mt-3 align-middle border-top"
          responsive
          borderless
        >
          <thead>
            <tr>
              <th>Transaction Date</th>
              <th>Customer Name</th>
              <th>Transaction ID</th>
              <th>UpiId</th>
              <th>Amount</th>
              <th>Status</th>
              <th>In/Out</th>
            </tr>
          </thead>
          <tbody>
            {loading ? (
              <Loader />
            ) : (
              singleTransferList?.length > 0 &&
              singleTransferList.map((item, index) => {
                return (
                  <tr key={index} className="border-top">
                    <td>{item.txn_time}</td>
                    <td>{item.customer_name}</td>
                    <td>{item.ext_transaction_id}</td>
                    <td>{item.upi_id}</td>
                    <td>{item.amount}</td>
                    <td>{item.transaction_status}</td>
                    <td>{item.in_out}</td>
                  </tr>
                );
              })
            )}
            {!loading && singleTransferList?.length === 0 && (
              <tr>
                <td colSpan={7}>
                  <h6 className="align-middle text-center fs-3 my-5 py-5">
                    No Data Available
                  </h6>
                </td>
              </tr>
            )}
          </tbody>
        </Table>
      </Row>
    </div>
  );
};

export default SingleTransferTab;
