import React, { useEffect, useState } from "react";
import { Col, Row, Button, Table, Input } from "reactstrap";
import { Link, useNavigate } from "react-router-dom";
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";
import User from "../../../assets/images/users/images.png";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import { FaUserTie } from "react-icons/fa";
const AddAdmin = () => {
  const [fileList, setFileList] = useState([]);
  let [fileName, setFileName] = useState("");
  const [image, setImage] = useState("");
  const [sendImage, setSendImage] = useState(false);
  let [loading, setLoading] = useState(false);
  let [loading1, setLoading1] = useState(false);
  const [show, setShow] = useState(false);

  let navigate = useNavigate();

  let [addData, setAddData] = useState({
    // public_id: 0,
    name: "",
    nameerr: "",
    phone_number: "",
    phone_numbererr: "",
    email: "",
    emailerr: "",
    role: "",
    password: "",
    passworderr: "",
    confirm_password: "",
    confirm_passworderr: "",
  });
  let [addconData, setAddconData] = useState({
    source: "",
    sourceerr: "",
    channel: "",
    channelerr: "",
    ext_transaction_id: "",
    ext_transaction_iderr: "",
    terminal_id: "",
    terminal_iderr: "",
    cid: "",
    ciderr: "",
    sid: "",
    siderr: "",
    checksum_key: "",
    checksum_keyerr: "",
    encryption_key: "",
    encryption_keyerr: "",
    time_pay_host: "",
    time_pay_hosterr: "",
  });

  let imageHandler = (e) => {
    const reader = new FileReader();
    reader.onload = () => {
      if (reader.readyState === 2) {
        setImage(reader.result);
      }

      setSendImage(e.target.files[0]);
    };
    reader.readAsDataURL(e.target.files[0]);
  };
  const updateProfile = (event) => {
    event.preventDefault();
    setLoading(true);
    let error = {
      status: 0,
      name: "",
      lastname: "",
      email: "",
      password: "",
      confirm_password: "",
    };
    if (addData.name == "") {
      error.name = "Please enter name.";
      error.status = 1;
    } else {
      error.name = "";
    }
    if (addData.phone_number <= 0 || addData.phone_number == "") {
      error.phone_number = "Please enter Mobile number.";
      error.status = 1;
    } else {
      error.phone_number = "";
    }
    if (addData.email <= 0 || addData.email == "") {
      error.email = "Please enter email.";
      error.status = 1;
    } else {
      error.email = "";
    }
    if (addData.password == "") {
      error.password = "Please enter Password.";
      error.status = 1;
    } else {
      error.password = "";
    }
    if (addData.confirm_password == "") {
      error.confirm_password = "Please enter Confirm Password.";
      error.status = 1;
    } else {
      error.confirm_password = "";
    }
    setAddData({
      ...addData,
      nameerr: error.name,
      phone_numbererr: error.phone_number,
      emailerr: error.email,
      passworderr: error.password,
      confirm_passworderr: error.confirm_password,
    });
    if (error.status == 1) {
      setLoading(false);
    }
    if (error.status == 0) {
      let config = {
        headers: {
          "Access-Control-Allow-Origin": "*",
          // "Content-type": "application/json",
          "Content-Type": "multipart/form-data",
          Authorization: localStorage.getItem("escrowToken"),
        },
      };

      let url = process.env.REACT_APP_BASEURL + "v1/asset/sub_admin/";
      const formData = new FormData();

      if (!(sendImage === false)) {
        formData.append("profile", sendImage);
      }
      formData.append("name", addData.name);
      formData.append("email", addData.email);
      formData.append("phone_number", addData.phone_number);
      formData.append("role", "sub-admin");
      formData.append("password", addData.password);
      formData.append("confirm_password", addData.confirm_password);

      axios
        .post(url, formData, config)
        .then((res) => {
          setLoading(false);
          setAddData({ ...addData });
          console.log("ddddddsds", res.data.data.asset_id);
          if (res.data.data.asset_id) {
            localStorage.setItem("user_eid", res.data.data.asset_id);
          }
          toast.success("Added successfully", {
            autoClose: 3000,
            theme: "colored",
          });
          setShow(true);
          // navigate("/admin");
        })
        .catch((err) => {
          // console.log(err.response.data.errors[0].error);
          // console.log(err.response.data.errors[0].non_field_errors);
          setLoading(false);
          if (err.response.data.errors[0].error) {
            toast.error(err.response.data.errors[0].error, {
              autoClose: 3000,
              theme: "colored",
            });
          } else if (err.response.data.errors[0].non_field_errors) {
            toast.error(err.response.data.message, {
              autoClose: 3000,
              theme: "colored",
            });
          }
        });
    }
  };
  const createConfig = (event) => {
    event.preventDefault();
    setLoading1(true);
    let error = {
      status: 0,
      source: "",
      channel: "",
      ext_transaction_id: "",
      terminal_id: "",
      cid: "",
      sid: "",
      checksum_key: "",
      encryption_key: "",
      time_pay_host: "",
    };
    if (addconData.source == "") {
      error.source = "Please enter Source Id.";
      error.status = 1;
    } else {
      error.source = "";
    }
    if (addconData.channel == "") {
      error.channel = "Please enter Channel Id.";
      error.status = 1;
    } else {
      error.channel = "";
    }
    if (addconData.ext_transaction_id == "") {
      error.ext_transaction_id = "Please enter Transaction Id.";
      error.status = 1;
    } else {
      error.ext_transaction_id = "";
    }
    if (addconData.terminal_id == "") {
      error.terminal_id = "Please enter Terminal Id.";
      error.status = 1;
    } else {
      error.terminal_id = "";
    }
    if (addconData.cid == "") {
      error.cid = "Please enter CId.";
      error.status = 1;
    } else {
      error.cid = "";
    }
    if (addconData.sid == "") {
      error.sid = "Please enter SId.";
      error.status = 1;
    } else {
      error.sid = "";
    }
    if (addconData.checksum_key == "") {
      error.checksum_key = "Please enter Checksum Key.";
      error.status = 1;
    } else {
      error.checksum_key = "";
    }
    if (addconData.encryption_key == "") {
      error.encryption_key = "Please enter Encryption Key.";
      error.status = 1;
    } else {
      error.encryption_key = "";
    }
    if (addconData.time_pay_host == "") {
      error.time_pay_host = "Please enter Time Pay Host.";
      error.status = 1;
    } else {
      error.time_pay_host = "";
    }
    setAddconData({
      ...addconData,
      sourceerr: error.source,
      channelerr: error.channel,
      ext_transaction_iderr: error.ext_transaction_id,
      terminal_iderr: error.terminal_id,
      ciderr: error.cid,
      siderr: error.sid,
      checksum_keyerr: error.checksum_key,
      encryption_keyerr: error.encryption_key,
      time_pay_hosterr: error.time_pay_host,
    });
    if (error.status == 1) {
      setLoading1(false);
    }
    if (error.status == 0) {
      let config = {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "multipart/form-data",
          Authorization: localStorage.getItem("escrowToken"),
        },
      };

      let url = process.env.REACT_APP_BASEURL + "/v1/create_config/";
      const formData = new FormData();
      let user_id, subadmin_user_eid;
      if (localStorage.getItem("user_eid")) {
        user_id = localStorage.getItem("user_eid");
      }
      if (localStorage.getItem("sub-admin_user_eid")) {
        subadmin_user_eid = localStorage.getItem("sub-admin_user_eid");
      }
      console.log("user_id", user_id);
      formData.append("source", addconData.source);
      formData.append("channel", addconData.channel);
      formData.append("ext_transaction_id", addconData.ext_transaction_id);
      formData.append("terminal_id", addconData.terminal_id);
      formData.append("cid", addconData.cid);
      formData.append("sid", addconData.sid);
      formData.append("checksum_key", addconData.checksum_key);
      formData.append("encryption_key", addconData.encryption_key);
      formData.append("time_pay_host", addconData.time_pay_host);
      if (localStorage.getItem("user_role") === "sub-admin") {
        formData.append("user_eid", subadmin_user_eid);
      } else {
        formData.append("user_eid", user_id);
      }

      axios
        .post(url, formData, config)
        .then((res) => {
          setLoading1(false);
          setAddconData({ ...addconData });

          toast.success("Added successfully", {
            autoClose: 3000,
            theme: "colored",
          });
          navigate("/admin");
        })
        .catch((err) => {
          setLoading1(false);
          toast.error(err.response.data.message, {
            autoClose: 3000,
            theme: "colored",
          });
        });
    }
  };

  useEffect(() => {
    if (localStorage.getItem("user_role") === "sub-admin") {
      setShow(true);
    }
  }, []);

  return (
    <>
      <Row>
        <Col
          className="header-color"
          style={{
            padding: "20px 20px 100px 20px",
          }}
        >
          <h2 className="text-white">
            {" "}
            <FaUserTie /> Sub-Admin
          </h2>
          <span className="text-white"> </span>
        </Col>
      </Row>
      <Row style={{ marginTop: "-50px" }}>
        <Col lg={12}>
          <Card>
            <Card.Header
              className="subheader-color"
              style={{ backgroundColor: "", padding: "20px" }}
            >
              <div
                className="d-flex justify-content-between "
                style={{ color: "#0149AD", fontWeight: "bold" }}
              >
                <div style={{ marginLeft: "20px" }}>Add Sub-Admin Form</div>
                <div
                  style={{
                    marginRight: "20px",
                    color: "#0149AD",
                    fontWeight: "400",
                  }}
                >
                  <nav aria-label="breadcrumb">
                    <ol className="breadcrumb ml-5 ">
                      <li className="breadcrumb-item">
                        <Link
                          to="/admin"
                          style={{ textDecoration: "none", color: "#1b385b" }}
                        >
                          Sub Admin
                        </Link>
                      </li>
                      <li
                        className="breadcrumb-item active"
                        aria-current="page"
                      >
                        <Link style={{ textDecoration: "none" }}>
                          Add Sub Admin
                        </Link>
                      </li>
                    </ol>
                  </nav>
                </div>
              </div>
            </Card.Header>
            <Card.Body>
              {localStorage.getItem("user_role") === "sub-admin" ? null : (
                <Form onSubmit={updateProfile}>
                  <Row className="mt-3">
                    <Col>
                      <label> Name</label>
                      <Input
                        name="name"
                        type="text"
                        placeholder="Name"
                        className="mt-2"
                        defaultValue={addData.name}
                        onChange={(e) => {
                          e.target.value !== ""
                            ? setAddData({
                                ...addData,
                                name: e.target.value,
                                nameerr: "",
                              })
                            : setAddData({
                                ...addData,
                                name: "",
                                nameerr: "Full name is required.",
                              });
                        }}
                      />
                      <small className="text-danger">{addData.nameerr}</small>
                    </Col>
                    <Col>
                      <label>Email</label>
                      <Input
                        name="email"
                        type="email"
                        placeholder="Email"
                        className="mt-2"
                        defaultValue={addData.email}
                        onChange={(e) => {
                          e.target.value !== ""
                            ? setAddData({
                                ...addData,
                                email: e.target.value,
                                emailerr: "",
                              })
                            : setAddData({
                                ...addData,
                                email: "",
                                emailerr: "Email  is required.",
                              });
                        }}
                      />
                      <small className="text-danger">{addData.emailerr}</small>
                    </Col>
                  </Row>
                  <Row className="mt-3">
                    <Col>
                      <label> Password</label>
                      <Input
                        name="password"
                        type="text"
                        placeholder="Password"
                        className="mt-2"
                        defaultValue={addData.password}
                        onChange={(e) => {
                          e.target.value !== ""
                            ? setAddData({
                                ...addData,
                                password: e.target.value,
                                passworderr: "",
                              })
                            : setAddData({
                                ...addData,
                                password: "",
                                passworderr: "Password is required.",
                              });
                        }}
                      />
                      <small className="text-danger">
                        {addData.passworderr}
                      </small>
                    </Col>
                    <Col>
                      <label>Confirm Password</label>
                      <Input
                        name="confirm_password"
                        type="text"
                        placeholder="Confirm Password"
                        className="mt-2"
                        defaultValue={addData.confirm_password}
                        onChange={(e) => {
                          e.target.value !== ""
                            ? setAddData({
                                ...addData,
                                confirm_password: e.target.value,
                                confirm_passworderr: "",
                              })
                            : setAddData({
                                ...addData,
                                confirm_password: "",
                                confirm_passworderr:
                                  "Confirm Password is required.",
                              });
                        }}
                      />
                      <small className="text-danger">
                        {addData.confirm_passworderr}
                      </small>
                    </Col>
                  </Row>
                  <Row className="mt-3">
                    <Col lg="6">
                      <label>Mobile no</label>
                      <Input
                        name="mobile"
                        type="tel"
                        placeholder="Mobile No"
                        className="mt-2"
                        maxLength={10}
                        defaultValue={addData.phone_number}
                        onChange={(e) => {
                          e.target.value !== ""
                            ? setAddData({
                                ...addData,
                                phone_number: e.target.value,
                                phone_numbererr: "",
                              })
                            : setAddData({
                                ...addData,
                                phone_number: "",
                                phone_numbererr: "Mobile number  is required.",
                              });
                        }}
                      />
                      <small className="text-danger">
                        {addData.phone_numbererr}
                      </small>
                    </Col>
                  </Row>
                  {/* <Row className="mt-3">
                  <Col lg={6}>
                    <Row className="p-3">
                      <label htmlFor="file-upload" className="custom-file-upload text-center "
                        style={{
                          border: "1px solid #ccc",
                          display: "inline-block",
                          width: '200px', height: '180px',
                          cursor: " pointer"
                        }}>
                        {
                        image == '' ? 
                        <img src={User} style={{ width: '190px', height: '170px', marginLeft: '-10px', marginTop: '5px' }} /> 
                        :<img src={image} style={{ width: '190px', height: '170px', marginLeft: '-10px', marginTop: '5px' }} />
                        }
                        <input id="file-upload" type="file" style={{ display: 'none' }} onChange={imageHandler} />
                        <br />
                        <span id="imageName" style={{ color: " green" }}>{fileName}</span> 
                      </label>
                    </Row>
                  </Col>

                  <Col className="mt-2 d-flex flex-column" lg='3'>
                    <div><Input name="account_name" className='col-6' type="checkbox" placeholder="Enter Account Name" /> <span >Select All</span></div>
                    <div> <Input name="account_name" className='col-6' type="checkbox" placeholder="Enter Account Name" /> <span >Account List</span></div>
                    <div> <Input name="account_name" className='col-6' type="checkbox" placeholder="Enter Account Name" /> <span >Single Transfer</span></div>
                    <div> <Input name="account_name" className='col-6' type="checkbox" placeholder="Enter Account Name" /> <span >Bulk Transfer</span></div>
                    <div> <Input name="account_name" className='col-6' type="checkbox" placeholder="Enter Account Name" /> <span >Manage Payees List</span></div>
                    <div><Input name="account_name" className='col-6' type="checkbox" placeholder="Enter Account Name" /> <span >Manage Teams</span></div>
                    <div> <Input name="account_name" className='col-6' type="checkbox" placeholder="Enter Account Name" /> <span >My Profile</span></div>
                  </Col>
                  <Col className="mt-2 d-flex flex-column" lg='3'>
                    <div><Input name="account_name" className='col-6' type="checkbox" placeholder="Enter Account Name" /> <span >Inward Funds Deposit</span></div>
                    <div> <Input name="account_name" className='col-6' type="checkbox" placeholder="Enter Account Name" /> <span >Outward Funds Deposit</span></div>
                    <div><Input name="account_name" className='col-6' type="checkbox" placeholder="Enter Account Name" /> <span >Challen List</span></div>
                    <div> <Input name="account_name" className='col-6' type="checkbox" placeholder="Enter Account Name" /> <span >Create Challan Page</span></div>
                    <div><Input name="account_name" className='col-6' type="checkbox" placeholder="Enter Account Name" /> <span >Escrow List</span></div>
                    <div> <Input name="account_name" className='col-6' type="checkbox" placeholder="Enter Account Name" /> <span >Create Escrow Page</span></div>
                  </Col>
                  </Row> */}
                  <Row className="mt-4">
                    <Col>
                      {loading ? (
                        <Button
                          type="submit"
                          className="btn header-color"
                          style={{ borderColor: "#0149AD" }}
                          disabled
                        >
                          Submit...
                          <span
                            className="spinner-border spinner-border-sm"
                            role="status"
                            aria-hidden="true"
                          />
                        </Button>
                      ) : (
                        <Button
                          type="submit"
                          className="btn header-color"
                          style={{ borderColor: "#0149AD" }}
                        >
                          Submit
                        </Button>
                      )}
                    </Col>
                    {/* <Col className="text-end">
                    <Button
                      className="btn header-color"
                      style={{ borderColor: "#0149AD" }}
                      onClick={() => {
                        setShow(true);
                      }}
                    >
                      Add Details
                    </Button>
                  </Col> */}
                  </Row>
                </Form>
              )}
              {show && (
                <Form onSubmit={createConfig}>
                  <Row className="mt-3">
                    <Col>
                      <label> Source</label>
                      <Input
                        name="source"
                        type="text"
                        placeholder="Source"
                        className="mt-2"
                        defaultValue={addconData.source}
                        onChange={(e) => {
                          e.target.value !== ""
                            ? setAddconData({
                                ...addconData,
                                source: e.target.value,
                                sourceerr: "",
                              })
                            : setAddconData({
                                ...addconData,
                                source: "",
                                sourceerr: "Source Id is required.",
                              });
                        }}
                      />
                      <small className="text-danger">
                        {addconData.sourceerr}
                      </small>
                    </Col>
                    <Col>
                      <label>Channel</label>
                      <Input
                        name="channel"
                        type="text"
                        placeholder="Channel"
                        className="mt-2"
                        defaultValue={addconData.channel}
                        onChange={(e) => {
                          e.target.value !== ""
                            ? setAddconData({
                                ...addconData,
                                channel: e.target.value,
                                channelerr: "",
                              })
                            : setAddconData({
                                ...addconData,
                                channel: "",
                                channelerr: "Channel Id is required.",
                              });
                        }}
                      />
                      <small className="text-danger">
                        {addconData.channelerr}
                      </small>
                    </Col>
                  </Row>
                  <Row className="mt-3">
                    <Col>
                      <label>Transaction Id</label>
                      <Input
                        name="ext_transaction_id"
                        type="text"
                        placeholder="Transaction Id"
                        className="mt-2"
                        defaultValue={addconData.ext_transaction_id}
                        onChange={(e) => {
                          e.target.value !== ""
                            ? setAddconData({
                                ...addconData,
                                ext_transaction_id: e.target.value,
                                ext_transaction_iderr: "",
                              })
                            : setAddconData({
                                ...addconData,
                                ext_transaction_id: "",
                                ext_transaction_iderr:
                                  "Transaction Id is required.",
                              });
                        }}
                      />
                      <small className="text-danger">
                        {addconData.ext_transaction_iderr}
                      </small>
                    </Col>
                    <Col>
                      <label>Terminal Id</label>
                      <Input
                        name="terminal_id"
                        type="text"
                        placeholder="Terminal Id"
                        className="mt-2"
                        defaultValue={addconData.terminal_id}
                        onChange={(e) => {
                          e.target.value !== ""
                            ? setAddconData({
                                ...addconData,
                                terminal_id: e.target.value,
                                terminal_iderr: "",
                              })
                            : setAddconData({
                                ...addconData,
                                terminal_id: "",
                                terminal_iderr: "Terminal Id is required.",
                              });
                        }}
                      />
                      <small className="text-danger">
                        {addconData.terminal_iderr}
                      </small>
                    </Col>
                  </Row>
                  <Row className="mt-3">
                    <Col>
                      <label>CId</label>
                      <Input
                        name="cid"
                        type="text"
                        placeholder="Cid"
                        className="mt-2"
                        defaultValue={addconData.cid}
                        onChange={(e) => {
                          e.target.value !== ""
                            ? setAddconData({
                                ...addconData,
                                cid: e.target.value,
                                ciderr: "",
                              })
                            : setAddconData({
                                ...addconData,
                                cid: "",
                                ciderr: "CId is required.",
                              });
                        }}
                      />
                      <small className="text-danger">{addconData.ciderr}</small>
                    </Col>
                    <Col>
                      <label>SId</label>
                      <Input
                        name="sid"
                        type="text"
                        placeholder="Sid"
                        className="mt-2"
                        defaultValue={addconData.sid}
                        onChange={(e) => {
                          e.target.value !== ""
                            ? setAddconData({
                                ...addconData,
                                sid: e.target.value,
                                siderr: "",
                              })
                            : setAddconData({
                                ...addconData,
                                sid: "",
                                siderr: "SId is required.",
                              });
                        }}
                      />
                      <small className="text-danger">{addconData.siderr}</small>
                    </Col>
                  </Row>
                  <Row className="mt-3">
                    <Col>
                      <label>Checksum Key</label>
                      <Input
                        name="checksum_key"
                        type="text"
                        placeholder="Checksum Key"
                        className="mt-2"
                        defaultValue={addconData.checksum_key}
                        onChange={(e) => {
                          e.target.value !== ""
                            ? setAddconData({
                                ...addconData,
                                checksum_key: e.target.value,
                                checksum_keyerr: "",
                              })
                            : setAddconData({
                                ...addconData,
                                checksum_key: "",
                                checksum_keyerr: "Checksum Key is required.",
                              });
                        }}
                      />
                      <small className="text-danger">
                        {addconData.checksum_keyerr}
                      </small>
                    </Col>
                    <Col>
                      <label>Encryption Key</label>
                      <Input
                        name="encryption_key"
                        type="text"
                        placeholder="Encryption Key"
                        className="mt-2"
                        defaultValue={addconData.encryption_key}
                        onChange={(e) => {
                          e.target.value !== ""
                            ? setAddconData({
                                ...addconData,
                                encryption_key: e.target.value,
                                encryption_keyerr: "",
                              })
                            : setAddconData({
                                ...addconData,
                                encryption_key: "",
                                encryption_keyerr:
                                  "Encryption Key is required.",
                              });
                        }}
                      />
                      <small className="text-danger">
                        {addconData.encryption_keyerr}
                      </small>
                    </Col>
                  </Row>
                  <Row className="mt-3">
                    <Col lg="6">
                      <label>Time Pay Host</label>
                      <Input
                        name="time_pay_host"
                        type="text"
                        placeholder="Time Pay Host"
                        className="mt-2"
                        defaultValue={addconData.time_pay_host}
                        onChange={(e) => {
                          e.target.value !== ""
                            ? setAddconData({
                                ...addconData,
                                time_pay_host: e.target.value,
                                time_pay_hosterr: "",
                              })
                            : setAddconData({
                                ...addconData,
                                time_pay_host: "",
                                time_pay_hosterr: "Time Pay Host is required.",
                              });
                        }}
                      />
                      <small className="text-danger">
                        {addconData.time_pay_hosterr}
                      </small>
                    </Col>
                  </Row>
                  <Row className="mt-4">
                    <Col>
                      {loading1 ? (
                        <Button
                          type="submit"
                          className="btn header-color"
                          style={{ borderColor: "#0149AD" }}
                          disabled
                        >
                          Submit...
                          <span
                            className="spinner-border spinner-border-sm"
                            role="status"
                            aria-hidden="true"
                          />
                        </Button>
                      ) : (
                        <Button
                          type="submit"
                          className="btn header-color"
                          style={{ borderColor: "#0149AD" }}
                        >
                          Submit
                        </Button>
                      )}
                    </Col>
                  </Row>
                </Form>
              )}
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default AddAdmin;
