import React, { useEffect, useState } from "react";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import { Col, Row } from "reactstrap";
import Card from "react-bootstrap/Card";
import { CiWallet } from "react-icons/ci";
import AllTransactions from "./AllTransactions";
import CashTransaction from "./CashTransaction";
import RewardBonus from "./RewardBonus";
import axios from "axios";
import { errorHandler } from "../../../utils/ErrorHandler";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import Loader from "../../../layouts/loader/Loader";

const MyWalletTab = () => {
  let navigate = useNavigate();
  useEffect(() => {
    getWalletData();
  }, []);

  const [walletData, setWalletData] = useState();
  const [loading, setLoading] = useState(false);
  const getWalletData = () => {
    setLoading(true);
    let url = process.env.REACT_APP_BASEURL + "v1/wallet/";
    const config = {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        Authorization: localStorage.getItem("escrowToken"),
      },
    };
    axios
      .get(url, config)
      .then((res) => {
        if (res) {
          setWalletData(res.data.data);
          // console.log("hfhhff",res.data.data);
          setLoading(false);
        }
      })
      .catch((error) => {
        setLoading(false);
        const getErrRes = errorHandler(error);
        if (getErrRes === 401) {
          toast.error("Something went wrong.", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 2000,
            theme: "colored",
          });
          navigate("/");
        } else {
          toast.error(getErrRes ? getErrRes : "Something went wrong.", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 2000,
            theme: "colored",
          });
        }
      });
  };
  return (
    <>
      {loading ? <Loader /> : null}
      <Row>
        <Col
          className="header-color"
          style={{ padding: "20px 20px 100px 20px" }}
        >
          <h3 className="text-white" style={{ fontWeight: "bold" }}>
            <CiWallet /> My Wallet
          </h3>
          <span className="text-white">
            Wallet:
            <span className="fw-bold fs-4">
              {" "}
              ₹{walletData?.wallet_amount ? walletData?.wallet_amount : 0}
            </span>
          </span>
        </Col>
      </Row>
      <Row style={{ marginTop: "-50px" }}>
        <Col lg={12}>
          <Card>
            <Card.Body style={{ padding: "0px" }}>
              <Tabs
                defaultActiveKey="description"
                id="uncontrolled-tab-example"
                className="subheader-color"
                style={{ marginTop: "-15px" }}
              >
                <Tab eventKey="description" title="All Transactions">
                  <AllTransactions />
                </Tab>
                <Tab eventKey="linkpayee" title="My Cash">
                  <CashTransaction />
                </Tab>
                <Tab eventKey="documents" title="Reward Bonus">
                  <RewardBonus />
                </Tab>
              </Tabs>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default MyWalletTab;
